import React from "react"
import { Link } from "gatsby"
import { toolbarLinks, subscribeLink } from "./siteLinks"
import styled from "styled-components"

const SideDraw = ({ show, drawerClickHandler, smallToolbar }) => {
  let drawerClass = ""
  if (show) {
    drawerClass = "open"
  }

  return (
    <SideDrawNav className={`${drawerClass} ${smallToolbar}`}>
      <SideDrawUl>
        {toolbarLinks.map((link, index) => (
          <SideDrawLi key={index}>
            <Link to={link.link} onClick={drawerClickHandler}>
              {link.name}
            </Link>
          </SideDrawLi>
        ))}
        <SideDrawLi>
          <Link to={subscribeLink.link} onClick={drawerClickHandler}>
            {subscribeLink.name}
          </Link>
        </SideDrawLi>
      </SideDrawUl>
    </SideDrawNav>
  )
}

export default SideDraw

const SideDrawNav = styled.nav`
  background: ${props => props.theme.color.background2};
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 5rem;
  left: 0;
  width: 200px;
  z-index: 60;
  transform: translateY(-100%);
  transition: transform 0.3s, top 0.3s;
  &.open {
    transform: translateY(0);
  }
  & a {
    color: ${props => props.theme.color.toolbarFontColor};
    text-decoration: none;
    font-size: 1.2rem;
  }
  & a:hover,
  & a:active {
    cursor: pointer;
    color: lightgray;
  }
  &.smaller {
    top: 3.5rem;
  }
`
const SideDrawUl = styled.ul`
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`
const SideDrawLi = styled.li`
  margin: 0.25rem 1rem;
`
