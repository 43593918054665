const theme = {
  color: {
    background: "black",
    background2: "rgb(33,33,33)",
    backgroundGradient:"linear-gradient(90deg, rgba(33,33,33,1) 0%, rgba(5,5,5,1) 50%, rgba(33,33,33,1) 100%)",
    homePageTitleBackground: "rgb(33,33,33,0.75)",
    secondaryBackground: "linear-gradient(rgba(0, 0, 0, 1),rgb(33,33,33))",
    toolbarFontColor: "white",
    fontColor: "white",
    radioButtonSelector: '#2196F3',
    disabledButton: '#4a4a4a'
  },
  mediaQuery: {
    xs: styles => `
        @media only screen and (max-width:480px) {
          ${styles}
        }
      `,
    xsMin: styles => `
      @media only screen and (min-width:480px) {
        ${styles}
      }
    `,
    md: styles => `
      @media only screen and (max-width:995px) {
        ${styles}
      }
    `,
    mdMin: styles => `
      @media only screen and (min-width:995px) {
        ${styles}
      }
    `,
    xlgMin: styles => `
      @media only screen and (min-width:1200px) {
        ${styles}
      }
    `,
  },
}

export default theme
