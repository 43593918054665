import React from "react"
import { Link } from "gatsby"
import { SubscribeBtnContainer, SubscribeP } from "./toolbarStyles"
import { subscribeLink } from "./siteLinks"

function SubscribeButton({ hideScreenSize }) {
  return (
    <SubscribeBtnContainer hide={hideScreenSize}>
      <Link to={subscribeLink.link}>
        <h4>{subscribeLink.name}</h4>
        <SubscribeP>For Show Times</SubscribeP>
      </Link>
    </SubscribeBtnContainer>
  )
}

export default SubscribeButton
