import styled from "styled-components"

export const ToolbarHeader = styled.header`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => `${props.theme.color.background}`};
  height: 5rem;
  transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  &.smaller {
    height: 3.5rem;
  }
`
export const ToolbarNav = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  padding-left: 0;
  ${props =>
    props.center &&
    props.theme.mediaQuery[props.center](`
        display: grid;
        place-items: center;
        border-bottom: 2px solid white;
      `)}
`
export const ToggleBtnContainer = styled.div`
  display: none;
  position: fixed;
  top: calc(2.5rem - 10px);
  left: 0;
  margin: 0 0.5rem;
  transition: top 0.3s;
  -webkit-transition: top 0.3s;
  -moz-transition: top 0.3s;
  -ms-transition: top 0.3s;
  -o-transition: top 0.3s;
  ${props =>
    props.show &&
    props.theme.mediaQuery[props.show](`
      display: block;
    `)}
  &.smaller {
    top: calc(1.75rem - 10px);
  }
`
export const Spacer = styled.div`
  flex: 1;
`
export const LogoContainer = styled.div`
  height: 4.5rem;
  width: 253.047px;
  margin: 0 0.5rem;
  transition: height 0.3s, width 0.3s;
  &.smaller {
    height: 3.5rem;
    width: 168.688px;
  }
`
export const LogoImage = styled.img`
  max-height: 100%;
  width: 100%;
`

export const ToolbarLinkContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 201;
  align-items: center;
  top: 5rem;
  left: 0;
  height: 2rem;
  width: 100%;
  background: ${props => `${props.theme.color.background}`};
  border-bottom: 2px solid white;
  transition: top 0.3s;
  -webkit-transition: top 0.3s;
  -moz-transition: top 0.3s;
  -ms-transition: top 0.3s;
  -o-transition: top 0.3s;
  &.smaller {
    top: 3rem;
  }
  ${props =>
    props.hide &&
    props.theme.mediaQuery[props.hide](`
      display: none;
    `)}
`
export const ToolbarUl = styled.ul`
  display: flex;
  height: 100%;
  margin: 0.5rem;
  padding: 0;
  list-style: none;
`

export const ToolbarLi = styled.li`
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 0.5rem 0.5rem 0 0;
  border: 2px solid transparent;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  &:hover,
  &:active,
  &.siteActive {
    position: relative;
    top: 2px;
    background: ${({ theme }) => theme.color.background2};
    border: 2px solid white;
    border-bottom: 2px solid ${({ theme }) => theme.color.background2};
  }
  & a {
    text-decoration: none;
    color: white;
  }
  & h4 {
    margin: 0;
  }
`

export const SubscribeBtnContainer = styled.div`
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
  border: 2px solid white;
  width: 110px;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  ${props =>
    props.hide &&
    props.theme.mediaQuery[props.hide](`
      display: none;
    `)}
  &:hover,
  &:active {
    position: relative;
    top: 2px;
    background: ${({ theme }) => theme.color.background2};
  }
  & a {
    text-decoration: none;
    color: white;
    display: grid;
    place-items: center;
  }
  & h4 {
    margin: 0;
  }
`
export const SubscribeP = styled.p`
  font-size: 0.75rem;
`
