import React from "react";
import styled from "styled-components"

const DrawToggleButton = props => (
  <ToggleButtonContainer onClick={props.click} >
    <ToggleButtonLine />
    <ToggleButtonLine />
    <ToggleButtonLine />
  </ToggleButtonContainer>
);

export default DrawToggleButton;

const ToggleButtonContainer = styled.button.attrs({
  "aria-label":"Menu"
})`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  width: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`
const ToggleButtonLine = styled.div`
  width: 25px;
  height: 2px;
  background: white;
`