export const toolbarLinks = [
   {
      name: 'SHOW TIMES',
      link: '/show-times/',
   },
   {
      name: 'COMING SOON',
      link: '/coming-soon/',
   },
   {
      name: 'OFFERS',
      link: '/offers/',
   },
   {
      name: 'FUNDRAISING',
      link: '/fundraising/',
   },
   // {
   //    name: 'EVENTS',
   //    link: '/events/',
   // },
   {
      name: 'SCHOOL GROUPS',
      link: '/school-groups/',
   },
   {
      name: 'KIDS ACTIVITIES',
      link: '/kid-activities/',
   },
   {
      name: 'JOBS',
      link: '/jobs/',
   },
   // {
   //    name: 'DRY 2 FORM',
   //    link: '/force-of-nature-the-dry-two-form',
   // },
   // {
   //   name: "HEALTH & SAFETY",
   //   link: "/health-and-safety/",
   // },
]

//THIS FILE IS REQUIRED UNTIL UPGRADED TO NEW VERSION OF WEBSITE

export const subscribeLink = {
   name: 'SUBSCRIBE',
   link: '/subscribe/',
}

export const footerLinks = [
   {
      name: 'CONTACT US',
      link: '/contact-us/',
   },
   {
      name: 'CINEMA LOCATION',
      link: '/cinema-location/',
   },
   {
      name: 'CLASSIFICATIONS',
      link: 'https://www.classification.gov.au/classification-ratings/what-do-ratings-mean',
   },
   {
      name: 'ONLINE TERMS & CONDITIONS',
      link: '/terms-conditions/',
   },
]
