import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useLocation } from '@reach/router'

import {
  ToolbarHeader,
  ToolbarNav,
  LogoContainer,
  Spacer,
  ToggleBtnContainer,
  ToolbarLi,
  ToolbarUl,
  ToolbarLinkContainer,
} from "./toolbarStyles"
import DrawToggleButton from "./DrawToggleButton"
import { toolbarLinks } from "./siteLinks"
import SubscribeButton from "./SubscribeButton"

const Header = ({ siteTitle, drawerToogleClickHandler, smallToolbar }) => {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query LogoGatsbyImage {
      file(base: { eq: "stadium4-icon.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <header>
      <ToolbarHeader className={smallToolbar}>
        <ToolbarNav center="md">
          <LogoContainer className={smallToolbar}>
            <Link to="/">
              <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                alt="Stadium 4 Cinema Leongatha"
                loading="eager"
                draggable={false}
              />
            </Link>
          </LogoContainer>
          <Spacer />
          <ToggleBtnContainer show="md" className={smallToolbar}>
            <DrawToggleButton click={drawerToogleClickHandler} />
          </ToggleBtnContainer>
          <SubscribeButton hideScreenSize="md" />
        </ToolbarNav>
      </ToolbarHeader>
      <ToolbarLinkContainer hide="md" className={smallToolbar}>
        <ToolbarUl>
          {toolbarLinks.map((link, index) => {
            let activeClass;
            if(pathname === link.link){
              activeClass = "siteActive"
            }
            return(
              <ToolbarLi key={index} className={activeClass}>
                <Link to={link.link}>
                  <h4>{link.name}</h4>
                </Link>
              </ToolbarLi>
            )
          })}
        </ToolbarUl>
      </ToolbarLinkContainer>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
