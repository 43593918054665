exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cinema-location-js": () => import("./../../../src/pages/cinema-location.js" /* webpackChunkName: "component---src-pages-cinema-location-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-fundraising-js": () => import("./../../../src/pages/fundraising.js" /* webpackChunkName: "component---src-pages-fundraising-js" */),
  "component---src-pages-health-and-safety-tsx": () => import("./../../../src/pages/health-and-safety.tsx" /* webpackChunkName: "component---src-pages-health-and-safety-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-kid-activities-js": () => import("./../../../src/pages/kid-activities.js" /* webpackChunkName: "component---src-pages-kid-activities-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-school-groups-js": () => import("./../../../src/pages/school-groups.js" /* webpackChunkName: "component---src-pages-school-groups-js" */),
  "component---src-pages-show-times-js": () => import("./../../../src/pages/show-times.js" /* webpackChunkName: "component---src-pages-show-times-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-movie-details-js": () => import("./../../../src/templates/movie-details.js" /* webpackChunkName: "component---src-templates-movie-details-js" */),
  "component---src-templates-show-times-film-template-js": () => import("./../../../src/templates/show-times-film-template.js" /* webpackChunkName: "component---src-templates-show-times-film-template-js" */)
}

