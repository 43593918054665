import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { QueryClient, QueryClientProvider } from "react-query"

import themeContext from "./src/utils/theme"

import type {WrapRootElementBrowserArgs} from "gatsby"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: rgb(33,33,33);
    height: 100vh;
    width: 100%;
  }
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100vh;
    width: 100%; 
  }
`

// const onRedirectCallback = appState => navigate(appState?.returnTo || "/")
const queryClient = new QueryClient()

export const wrapRootElement = ({ element }:WrapRootElementBrowserArgs) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN as string}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID as string}
    authorizationParams={{
      redirect_uri: process.env.GATSBY_AUTH0_REDIRECT as string,
      audience: process.env.GATSBY_AUTH0_AUDIENCE as string,
      scope: process.env.GATSBY_AUTH0_SCOPE as string,
    }}
  >
    <ThemeProvider theme={themeContext}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        {element}
      </QueryClientProvider>
    </ThemeProvider>
  </Auth0Provider>
)

export { wrapPageElement } from "./src/components/layout/wrapPageElement"
