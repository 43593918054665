import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"

import Header from "./layout/header"
import Footer from "./layout/footer"
import Backdrop from "./layout/Backdrop"
import SideDraw from "./layout/SideDraw"
import { SmallToolbarContext } from "./context/smallToolbarContext"

const Layout = ({ children, location }) => {
  const [drawerState, setDrawerState] = useState(false)
  const [smallToolbar, setSmallToolbar] = useState("")
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll)
    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll)
    }
  }, [])

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200
    if (distanceY > shrinkOn) {
      setSmallToolbar("smaller")
    } else {
      setSmallToolbar("")
    }
  }

  const drawerToogleClickHandler = () => setDrawerState(!drawerState)
  const backdropClickHandler = () => setDrawerState(false)
  let backdrop
  if (drawerState) {
    backdrop = <Backdrop click={backdropClickHandler} />
  }

  return (
    <SmallToolbarContext.Provider value={[smallToolbar, setSmallToolbar]}>
      <Grid collapseToolbar="md">
        <ToolBarStructure>
          <Header
            siteTitle={data.site.siteMetadata.title}
            drawerToogleClickHandler={drawerToogleClickHandler}
            location={location}
            smallToolbar={smallToolbar}
          />
          <SideDraw
            show={drawerState}
            drawerClickHandler={drawerToogleClickHandler}
            smallToolbar={smallToolbar}
          />
          {backdrop}
        </ToolBarStructure>
        <MainStructure>{children}</MainStructure>
        <FooterStructure>
          <Footer />
        </FooterStructure>
      </Grid>
    </SmallToolbarContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Grid = styled.div`
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-areas:
    "nav"
    "main"
    "footer";
  -ms-grid-rows: 7rem 1fr auto;
  grid-template-rows: 7rem 1fr auto;
  ${props =>
    props.collapseToolbar &&
    props.theme.mediaQuery[props.collapseToolbar](css`
      -ms-grid-rows: 5rem 1fr auto;
      grid-template-rows: 5rem 1fr auto;
    `)}
`

const ToolBarStructure = styled.div`
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-row: 1;
  grid-column: 1;
  grid-area: nav;
  background-color: ${props => props.theme.color.background};
  z-index: 500;
`

const FooterStructure = styled.div`
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-row: 3;
  grid-column: 1;
  grid-area: footer;
  background-color: ${props => props.theme.color.background};
  z-index: 100;
  height: 100%;
`

const MainStructure = styled.div`
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-row: 2;
  grid-column: 1;
  grid-area: main;
  background: ${props => props.theme.color.backgroundGradient};
  padding: 0 2rem;
  margin: auto;
  height: 100%;
  width: 100%;

  ${props =>
    props.theme.mediaQuery["xs"](css`
      padding: 0 0.25rem;
    `)}
`
