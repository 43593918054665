import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { footerLinks } from "./siteLinks"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      IGImage: file(relativePath: { eq: "IG_Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 24, height: 24)
        }
      }
      FBImage: file(relativePath: { eq: "facebook_logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 24, height: 24)
        }
      }
    }
  `)
  return (
    <FooterContainer stack="md">
      <Flex>
        <SocialContainer>
          <SocialLink href="https://www.facebook.com/stadium4cinemas/" ariaLabel="Facebook">
            <SocialImg
              image={data.FBImage.childImageSharp.gatsbyImageData}
              alt="Facebook"
            />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/stadium4cinemas/" ariaLabel="Instagram">
            <SocialImg
              image={data.IGImage.childImageSharp.gatsbyImageData}
              alt="Instagram"
            />
          </SocialLink>
        </SocialContainer>
        <Spacer />
        <FooterLinkContainer hide="md">
          <Ul>
            {footerLinks.map((link, index) =>
              index !== 3 ? (
                <Li key={index} className="separator">
                  {link.name === "CLASSIFICATIONS" ? (
                    <CustomLink href={link.link}>{link.name}</CustomLink>
                  ) : (
                    <Link to={link.link}>{link.name}</Link>
                  )}
                </Li>
              ) : (
                <Li key={index}>
                  <Link to={link.link}>{link.name}</Link>
                </Li>
              )
            )}
          </Ul>
        </FooterLinkContainer>
        <Spacer />
        <FooterText>
          Stadium 4 Cinema, Leongatha
          <br />
          14 - 18 Smith Street
          <br />
          Leongatha VIC. 3953
          <br />
          Phone:{" "}
          <TelephoneLink href="tel:+61356625762">(03) 5662 5762</TelephoneLink>
        </FooterText>
      </Flex>
      <Flex hide="mdMin" center="md">
        <FooterLinkContainer>
          <Ul smallerFont="xs">
            {footerLinks.map((link, index) =>
              index !== 3 ? (
                <Li key={index} className="separator">
                  {link.name === "CLASSIFICATIONS" ? (
                    <CustomLink href={link.link}>{link.name}</CustomLink>
                  ) : (
                    <Link to={link.link}>{link.name}</Link>
                  )}
                </Li>
              ) : (
                <Li key={index}>
                  <Link to={link.link}>{link.name}</Link>
                </Li>
              )
            )}
          </Ul>
        </FooterLinkContainer>
      </Flex>
    </FooterContainer>
  )
}

export default Footer

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${props =>
    props.hide &&
    props.theme.mediaQuery[props.hide](`
    display: none;
  `)}
  ${props =>
    props.center &&
    props.theme.mediaQuery[props.center](`
    margin-top: 5px;
    justify-content: center;
  `)}
`
const Ul = styled.ul`
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  ${props =>
    props.smallerFont &&
    props.theme.mediaQuery[props.smallerFont](`
    font-size: 0.5rem; 
  `)}
`
const Li = styled.li`
  display: flex;
  align-items: flex-end;
  &:hover {
    cursor: pointer;
  }
  &.separator:after {
    content: " | ";
    padding: 0 5px;
  }
  & a {
    color: white;
  }
`
const CustomLink = styled.a.attrs(props => ({
  target: "_blank",
  rel: "noopener noreferrer",
}))`
  color: white;
`
const FooterLinkContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  ${props =>
    props.hide &&
    props.theme.mediaQuery[props.hide](`
    display: none;
  `)}
  ${props =>
    props.show &&
    props.theme.mediaQuery[props.show](`
    display: flex;
  `)}
`
const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  align-content: center;
  ${props =>
    props.stack &&
    props.theme.mediaQuery[props.stack](`
        flex-direction: column;
    `)}
`
const FooterText = styled.p`
  margin: 0 0.5rem;
  padding: 0.25rem 0;
  font-size: 0.75rem;
  color: white;
`
const Spacer = styled.div`
  flex: 1;
`
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  width: 6rem;
  margin: 0 0.5rem;
  padding: 0;
`
const SocialLink = styled.a.attrs(props => ({
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": `${props.ariaLabel}`
}))`
  margin: 0 0.5rem;
  padding: 0 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
`
const SocialImg = styled(GatsbyImage)`
  width: 1.5rem;
  height: 1.5rem;
`
const TelephoneLink = styled.a`
  color: white;
`
